  .partner {
    background-color: #fff;
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .main4 {
    padding-top: 3rem;
    padding-bottom: 8rem;
    display: block;
  }

  .main4-heading {
    padding: 0px;
    margin-bottom: 20px;
    text-align: center;
  }

  .main4-heading h1 {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    color: #2C2C2C;
  }

  .image-card:hover {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.image-card {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
}
  
  

@media (max-width: 768px) {
  .main4-heading h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .main4-heading h1 {
    font-size: 1.5rem;
  }
}



.InstaMobile {
  display: none;
}


@media (max-width: 768px) {
  .InstaMobile {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      max-width: 90%;
      margin: auto;
  }

  .instaInner {
      width: 100%;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      border-radius: 8px;
  }

  .instaInner a {
      display: block;
      width: 100%;
      height: 100%;
  }

  .instaPhoto {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
  }


  .instaPhoto:hover {
      transform: scale(1.1);
  }
}


@media (max-width: 480px) {
  .InstaMobile {
      gap: 5px; 
  }

  .instaPhoto:hover {
      transform: scale(1.05);
  }
}