@keyframes spins {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {

    .contactus h1 {
      font-size: 1.5rem;
    }
    
    .contactus p {
      font-size: 1rem;
    }
  
    .contactus button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    iframe {
      height: 300px;
    }
  }