
.main1 {
  height: auto;
  text-align: center;
  position: relative;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.intro {
 width: 100%;
 max-width: 1400px;
 display: block;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.title-container h1 {
  margin: 60px;
  font-size: 2.8rem;
  font-family: 'Montserrat', sans-serif;
  color: #2C2C2C;
}

.block-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 80%;
  margin-bottom: 60px;
  margin: auto;
}

.block {
  width: 100%;
  position: relative;
  padding: 10px;
  padding-top: 60px;
  padding-bottom: 40px;
  border-radius: 10px;
  border: 1px solid rgb(218, 218, 218);
  box-sizing: border-box;
  margin: 40px 0px;
  transition: 0.3s;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #7e7e7e;
  text-align: center;
}

.block p {
  display: flex;
}

.block:hover {
  transform: scale(1.03);
  transition: 0.3s;
}

.block h2 {
  padding-bottom: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #2C2C2C;
  font-size: 1.3rem;
}

.block img {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: auto;
}


.introMobile {
  display: none;
}

@media (max-width: 1024px) {
  .block-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .title-container h1 {
    margin: 60px 0px 20px 0px;
    font-size: 2.6rem;
  }
}

@media (max-width: 768px) {
  .intro {
    display: none;
  }

  .introMobile {
    display: block;
    width: 100%;
  }

  .block-container {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
  }

  .title-container {
    margin: 10px;
  }
  
  .title-container h1 {
    font-size: 2rem;
  }

  .blockMobile {
    padding: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgb(192, 192, 192);
    box-sizing: border-box;
    margin: 5px 0px;
    transition: 0.3s;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #5e5e5e;
    text-align: center;
    display: flex;
  }

  .blockMobile > div:first-child {
    display: flex;
    flex: 2;
  }

  .blockMobile > div:nth-child(2) {
    display: flex;
    flex: 8;
  }

  .blockMobile img {
    margin: auto;
  }

  .blockMobile h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin: auto;
  }

}

@media (max-width: 480px) {

  .title-container h1 {
    font-size: 1.5rem;
  }

  .blockMobile h2 {
    font-size: 1rem;
    font-weight: bold;
    margin: auto;
  }
}