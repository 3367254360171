.headerbody {
  width: 100%;
}

.header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: .5s;
  background-color: #363636;
  color: #fff;
}

.header a {
  color: #fff;
}

.header .hLogo {
  color: #fff;
  background-image: url('../asset/darli_w.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 148px;
  height: 60px;
  margin: 10px;
}

.header-bg {
  background-color: #ffffff;
  transition: .5s;
  height: 70px;
  display: flex;
  color: black;
}

.header-bg a {
  color: black;
}

.header-bg .hLogo {
  color: black;
  background-image: url('../asset/darli_b.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 148px;
  height: 60px;
}

.header-content {
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.left-content {
  flex: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.left-content span {
  cursor: pointer;
}

.center-content {
  flex: 3;
  display: flex;
  justify-content: center;
  min-width: 760px;
}

.center-content span {
  margin: auto 18px;
  font-size: 16px;
  font-weight: bold;
}

.center-content span:hover {
  color: rgb(206, 186, 74);
  transition: 0.3s;
}

.right-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.right-content span {
  margin: auto 10px;
  font-weight: bold;
  cursor: pointer;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
}

.hamburger-menu span {
  height: 3px;
  background-color: rgb(255, 255, 255);
  transition: 0.3s;
}

.hamburger-menu > span:nth-child(2) {
  margin-top: 5px;
}

.hamburger-menu > span:nth-child(3) {
  margin-top: 5px;
}

.header-bg .hamburger-menu span {
  background-color: rgb(0, 0, 0);
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #363636;
  height: calc(100vh - 70px);
  max-height: 100vh;
  overflow-y: scroll;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.dropdown-inner a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid #ffffff;
  padding: 14px;
  font-size: 2rem;
  font-weight: bold;
}

.dropdown-inner span {
  padding: 14px;
}

.dropdown-inner a span:first-child {
  margin-right: auto;
}

.inner-top {
  background-color: #FFFFFF;
  padding: 10px 0px;
}

.dropdown-bottom {
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.dropdown-bottom > div > span {
  background-color: #D9D9D9;
  padding: 8px 14px;
  margin-left: 14px;
  font-size: 1.2rem;
  border-radius: 24px;
}

.close {
  width: 20px;
  cursor: pointer;
}

.profile-section {
  display: flex;
  padding: 10px 0px;
}
.profile-section div {
  margin: auto 12px;
  color: black;
}

.user-profile {
  width: 80px;
}

.aboutus-menu {
  border-bottom: 1px solid #ffffff;
  padding: 10px 20px;
}

.aboutus-menu a{
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: none;
}

.extend {
  width: 30px;
}

@media (max-width: 1300px) {
  .center-content, .right-content {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  .left-content {
    font-size: 22px;
  }

  .dropdown-menu {
    width: 100%;
    right: 0;
  }
}

@media (max-width: 480px) {
  .header-content {
    padding: 0 15px;
  }

  .left-content {
    font-size: 20px;
  }

  .header .hLogo {
    width: 108px;
    height: 20px;
    margin: 10px;
  }
  
  .dropdown-inner a {
    padding: 12px;
    font-size: 1.8rem;
  }  
  
  .dropdown-inner span {
    padding: 12px;
  }
  
  .dropdown-bottom > div > span {
    padding: 6px 20px;
    font-size: 1rem;
  }

  .aboutus-menu a{
    font-size: 1.2rem;
  }
}

@media (max-width: 320px) {
  .header-content {
    padding: 0 10px;
  }

  .left-content {
    font-size: 18px;
  }

  .dropdown-inner a {
    padding: 10px;
    font-size: 1rem;
  }
  
  .dropdown-inner span {
    padding: 10px;
  }
  
  .dropdown-bottom > div > span {
    padding: 6px 20px;
    font-size: 0.8rem;
  }

  .aboutus-menu a{
    font-size: 0.8rem;
  }
}


.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
}

.login-heading {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}

.login-modal > form {
  display: flex;
  flex-direction: column;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  line-height: 25px;
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 10px 0px 0px 10px;
}

.signform {
  width: 340px;
}

.phone-area {
  display: flex;
  margin: 10px 0px;
  padding: 5px 2px 2px 5px;
}

.phone-area input {
  height: 40px;
  border: 1px solid #CACACA;
  width: 100%;
}

.phone {
  border-radius: 0px 10px 10px 0px;
  font-size: 1rem;
  padding: 5px 2px 2px 5px;
}

.register-area {
  display: flex;
  margin: 10px 0px;
  border-radius: 0px 10px 10px 0px;
  font-size: 1rem;
  padding: 5px 2px 2px 5px;
  width: 100%;
}

.register-area input {
  height: 40px;
  border: 1px solid #CACACA;;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.register-area button {
  width: 100px;
  border-radius: 5px;
  padding: 8px;
  border: none;
  background-color: rgb(36, 36, 36);
  color: #fff;
  font-weight: bold;
  margin: auto;
}

.react-tel-input {
  max-width: 100px;
}

.react-tel-input .flag-dropdown {
  border-radius: 5px 0px 0px 5px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 5px 0px 0px 5px;
}


@media (max-width: 480px) {
  .login-modal {
    width: 300px;
    padding: 2rem 0.4rem;
  }
  .signform {
    width: 100%;
  }
  .phone-area input {
    width: 100%;
    font-size: 0.8rem;
  }
  .phone-area button {
    width: 60px;
    font-size: 0.8rem;
  }
  .code input {
    width: 220px;
    font-size: 0.8rem;
  }
  .code button {
    width: 60px;
    font-size: 0.8rem;
  }
}