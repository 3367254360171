.aboutbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/h5.jpg');
    background-size: cover;
    background-position: center;
}

.aboutbannerMobile {
    position: relative;
    display: flex;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/h5.jpg');
    background-size: cover;
    background-position: center;
}

.aboutbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.aboutbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.aboutus-title {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    padding: 0px 10px;
  }
  
  .aboutus-title h1 {
    text-align: left;
    padding-top: 20px;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: #2C2C2C;
  }

  .aboutus-content {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    padding: 10px;
  }

  .aboutus-content p {
    text-align: left;
    padding-bottom: 40px;
    font-size: 0.9rem;
    font-family: 'Montserrat', sans-serif;
    color: #2C2C2C;
  }

  .aboutus-content h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    color: #171D75;
  }

.processbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/h6.jpg');
    background-size: cover;
    background-position: center;
}

.processbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.processbannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/h6.jpg');
    background-size: cover;
    background-position: center;
}

.processbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.contactusbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/contactus.jpg');
    background-size: cover;
    background-position: center;
}

.contactusbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ourproductbanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/ourproduct.jpg');
    background-size: cover;
    background-position: center;
}

.ourproductbannerMobile {
    position: relative;
    display: none;
    width: 100%; 
    height: 190px;
    padding-top: 60px;
    background-image: url('../asset/ourproduct.jpg');
    background-size: cover;
    background-position: center;
}

.ourproductbanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ourproductbannerMobile::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.inquirybanner {
    position: relative;
    display: flex;
    width: 100%; 
    height: 260px;
    padding-top: 40px;
    background-image: url('../asset/inquirybanner.jpg');
    background-size: cover;
    background-position: center;
}

.inquirybanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.aboutus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
}

.aboutt {
    margin: 50px 0px;
    padding: 0;
    display: block;
}

.abouttMobile {
    margin: 10px;
    padding: 0;
    display: none;
}

.abou {
    margin: 0;
    padding: 0;
    display: block;
    max-width: 100%;
    height: auto;
}


.aboutuss {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
}

.aboutt {
    margin: 50px 0px;
    padding: 0;
}

.abou {
    margin: 0;
    padding: 0;
    display: block;
    max-width: 100%;
    height: auto;
}




.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
}

.stylish-button {
  padding: 5px 40px;
  font-size: 2rem;
  color: white;
  background: #b6b6b6;
  border: none;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.stylish-button:hover {
  background: #363636;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.clicked {
    background: #363636;
}

.ourprdMobile {
    display: flex;
    margin: 10px 5px;
}

.productImg {
    flex: 3;
    border: 1px solid rgb(36, 42, 116);
    padding: 5px;
    display: flex;
}

.productImg span {
    margin: auto;
}

.productImg span > img {
    width: 100%;
}

.productDes {
    flex: 7;
    display: block;
    margin: 0px 5px;
}

.prd-title {
    font-size: 1.4rem;
    padding: 5px 10px;
    border-bottom: 1px solid rgb(36, 42, 116);
}

.prd-cate {
    font-size: 1.2rem;
    padding: 5px 10px;
    border-top: 1px solid rgb(36, 42, 116);
}

.prd-des {
    font-size: 1rem;
    padding: 5px 10px;
    border-top: 1px solid rgb(36, 42, 116);
    color: #6e6e6e;
}

@media (max-width: 768px) {

    .button-container {
        margin: 20px 0;
    }

    .stylish-button {
        padding: 10px 20px;
        font-size: 1.6rem;
    }

    .processbanner{
        display: none;
    }

    .processbannerMobile{
        display: flex;
    }

    .ourproductbanner {
        display: none;
    }

    .ourproductbannerMobile {
        display: flex;
    }

    .aboutt {
        display: none;
    }

    .abouttMobile {
        display: block;
    }



.prd-title {
    font-size: 1.2rem;
}

.prd-cate {
    font-size: 1rem;
}

.prd-des {
    font-size: 0.8rem;
}
    
}


@media (max-width: 484px) {
    .productImg {
        flex: 4;
    }
    
    .productDes {
        flex: 6;
    }
    .prd-title {
        font-size: 0.8rem;
    }
    
    .prd-cate {
        font-size: 0.7rem;
    }
    
    .prd-des {
        font-size: 0.6rem;
    }
}