.footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
  font-size: 1rem;
  position: relative;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  text-align: left;
  margin: 10px 0;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.about {
  display: flex;
  font-weight: bold;
  margin: auto 5px;
}

.about img {
  width: 100px;
}

.about p:first-child {
  padding-left: 10px;
}

div.footer-section.about > p:nth-child(2) {
  margin-top: 0px;
  padding-left: 10px;
}

.aboutMobile {
  display: none;
  font-size: 1rem;
}

.aboutMobile img {
  width: 120px;
}

.aboutMobile button {
  font-size: 1rem;
  background-color: #0b3bc0;
  padding: 8px 14px;
  border-radius: 8px;
}

.aboutMobile > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.aboutMobile > div:nth-child(2) {
  margin: 10px 0px;
  margin-top: 24px;

}

.contact-web div {
  display: flex;
  flex-wrap: wrap;
}

.footer-section p,
.footer-section ul,
.footer-section a {
  color: white;
  text-decoration: none;
  margin: auto 5px;
}

.divide {
  display: block;
}


@media (max-width: 768px) {

  .footer {
    padding: 20px 0px;
  }

  .footer-content {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;
  }

  .footer-section {
      width: 100%; 
      margin: 4px 0;
      text-align: center; 
  }

  .footer-section h2 {
      font-size: 1rem; 
  }

  .about,
  .contact,
  .copyright {
      font-size: 0.85rem;
  }

  .copyright {
    margin: 20px 0px;
  }

  .about {
      display: none;
  }

  .aboutMobile {
    display: block;
    flex-direction: column; 
    align-items: center;
  }

  .about a {
      margin: 5px 0; 
  }

  div.footer-section.about > p:nth-child(2) {
    margin-top: 10px;
    padding-left: 0px;
  }

  .contact-web div{
    display: none;
  }

  .contact div {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .contact div p {
      margin: 0px;
  }

  .divide {
      display: none;
  }

  .footer-section p {
      margin: 3px 0;
  }
}


.contact-content {
  display: none;
}

.show-more {
  display: none; 
  background: none;
  color: white;
  border: 1px solid white;
  padding: 5px 40px;
  cursor: pointer;
  font-size: 1rem;
}


@media (max-width: 768px) {
  .show-more {
    display: block; 
    margin: auto;
  }

  .contact-content.show {
    display: flex; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 340px) {

  .aboutMobile {
    font-size: 0.9rem;
  }

  .aboutMobile img {
    width: 100px;
  }

.aboutMobile button {
  font-size: 0.8rem;
}

.aboutMobile > div:first-child {
  padding: 0px 5px;
}

.show-more {
  font-size: 0.9rem;
}

.about,
.contact,
.copyright {
    font-size: 0.7rem;
}
}